import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function MainPage() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDOB] = useState('');
  const [smShow, setSmShow] = useState(false);
  const [wishlist1, setWishlist1] = useState('');
  const [wishlist2, setWishlist2] = useState('');
  const [wishlist3, setWishlist3] = useState('');
  const [wishlist1_link, setWishlist1_link] = useState('');
  const [wishlist2_link, setWishlist2_link] = useState('');
  const [wishlist3_link, setWishlist3_link] = useState('');
  const [christmasBaby, setXmasBaby] = useState('');
  const [wishData, setWishData] = useState<any[]>([]); // Specify the type as any[] initially.


  const fetchRaffleData = () => {
    axios
      .get(`https://backoffice.pulp.ph/api/wishlist/${firstName}/${lastName}/${dob}`)
      .then((response) => {
        const raffleData: any[] = response.data.wishlist; // Specify the type here as well.
        const raffleData1 = raffleData[0];
        if (raffleData1 && raffleData1.FirstName == firstName && raffleData1.LastName == lastName && raffleData1.DOB == dob) {
          setWishlist1(raffleData1.wishlist1); // Access the first element's prizeImage
          setWishlist2(raffleData1.wishlist2); // Access the first element's ticketNum
          setWishlist3(raffleData1.wishlist3); // Access the first element's ticketNum
          setWishlist1_link(raffleData1.wishlist1_link); // Access the first element's prizeImage
          setWishlist2_link(raffleData1.wishlist2_link); // Access the first element's ticketNum
          setWishlist3_link(raffleData1.wishlist3_link); // Access the first element's ticketNum
          setDOB(raffleData1.DOB);
          setXmasBaby(raffleData1.Christmas_Baby);
        }
        setWishData(raffleData);
        console.log(wishData);
      })
      .catch((error) => {
        // Handle any other errors, and set the image to 'NotWin.png'
        setWishData([]); // Initialize as an empty array
        console.error(error);
      });
  };

  const handleViewResult = () => {axios
    .get(`https://backoffice.pulp.ph/api/wishlist/${firstName}/${lastName}/${dob}`)
    .then((response) => {
      const raffleData: any[] = response.data.wishlist;
      const raffleData1 = raffleData[0];

      if (raffleData1 && raffleData1.FirstName == firstName && raffleData1.LastName == lastName && raffleData1.DOB == dob) {
        fetchRaffleData();
      } else {
        // Alert if the data doesn't match
        alert('Name or Date of Birth do not match. Please coordinate with Admin.');
      }
    })
    .catch((error) => {
      setWishData([]); // Initialize as an empty array
      console.error(error);
      alert('An error occurred. Please try again later.');
    });
  };

  return (

    <React.Fragment>
      <div className='container-fluid main'>
        {/* <div className='leftSideConainer'><img src={Xtree} className='Puno' /></div> */}
        <div className='rightSideContainer'>
          <div id="presale-form pt-3">
            <div className="presale-label1 pt-1 fs-4 text-md-start text-warning">Enter Your Name</div>
            <input
              type="text"
              id="FirstName"
              name="FirstName"
              placeholder='First Name eg. JUAN'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value.toUpperCase())}
              className="form-control ng-pristine ng-valid ng-touched"></input>
                          <input
              type="text"
              id="LastName"
              name="LastName"
              placeholder='Last Name eg. DELA CRUZ'
              value={lastName}
              onChange={(e) => setLastName(e.target.value.toUpperCase())}
              className="form-control ng-pristine ng-valid ng-touched"></input>
            <input
              type='text'
              id='code'
              name='code'
              placeholder='Date of Birth mmddyyyy eg. 01311991'
              value={dob}
              onChange={(e) => setDOB(e.target.value)}
              className='form-control ng-pristine ng-valid ng-touched'></input>
            <button
              type="button"
              id="presale-submit"
              onClick={handleViewResult}
              className="presale-btn1 slate" >View Result</button>
            <div className="line-hr"></div>
            <div className="container-fluid overflow-auto disclaimer-container">
              <div className="col-12">
                <h5 className="mb-4 text-warning"> Hi Secret Santa! below is your Christmas Baby's Wishlist:</h5>
                <p className="text-danger"> </p>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" className="silver">Christmas Baby</th>
                      <th scope="col" className="silver">Wishlist 1</th>
                      <th scope="col" className="silver">Wishlist 2</th>
                      <th scope="col" className="silver">Wishlist 3</th>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      wishData.map((pdata) => (
                        <tr>
                          <td className='silver'>{pdata.Christmas_Baby} </td>
                          <td className='silver'><a href={pdata.wishlist1_link} target='_blank' rel='noreferrer'>{pdata.wishlist1}</a> </td>
                          <td className='silver'><a href={pdata.wishlist2_link} target='_blank' rel='noreferrer'>{pdata.wishlist2}</a> </td>
                          <td className='silver'><a href={pdata.wishlist3_link} target='_blank' rel='noreferrer'>{pdata.wishlist3}</a> </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};
