import React from "react";
import Invitation from "../img/Invitation.jpg";

export default function FrontPage(){
    return(
        <React.Fragment>
            <div className="container">
              <img  src={Invitation} className="container-fluid" alt="Invitation"/>
            </div>
        </React.Fragment>
    )
}