import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function footer() {
    return (
        <React.Fragment>
        <p className="footer">Copyright <FontAwesomeIcon icon={faCopyright} size="2xs" /> 2023. PULP Group. All Rights Reserved. </p>
    </React.Fragment>
    );
  };