import React from "react";

export default function EventDetails() {
  return (
    <React.Fragment>
      <div className='container-fluid'>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td className='gold'><b>VENUE</b></td>
              <td className='silver'>Quezon City Sports Club</td>
            </tr>
            <tr>
              <td className='gold'><b>DATE</b></td>
              <td className='silver'>December 19, 2023</td>
            </tr>
            <tr>
              <td className='gold'><b>TIME</b></td>
              <td className='silver'>6:00PM to 10:00PM</td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}