import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./Pages/header";
import Footer from './Pages/footer';
import './App.css';
import MainPage from './Pages/MainPage';
import EventDetails from './Pages/EventDetails';
import FrontPage from './Pages/FrontPage';

export default function App() {
  return (
    <div className='container'>
    <Router>
      <Header/>
      <Routes>
      <Route path='/' element={<FrontPage />}/>
      <Route path='/Secret-Santa' element={<MainPage />}/>
      <Route path='/Event-Details' element={<EventDetails/>}/>
      </Routes>
      <Footer/>
    </Router>
    </div>
  );
};
